import React from "react";
import Helmet from "react-helmet";

export default () => (
	<Helmet title="Wedding Party | Alex Main">
		<meta
			http-equiv="Refresh"
			content="0; url=https://theknot.com/us/charlotte-and-alex-2020/wedding-party"
		/>
	</Helmet>
);
